



















































import { userModule } from '@/store'
import { OBJECT_VALUES_TO_STRING } from '@/helpers'
import { Component, Vue } from 'vue-property-decorator'

// Components
import TableRow from '@/components/table/Row.vue'
import TableHeading from '@/components/table/Heading.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import Pagination from '@/components/paginations/Pagination.vue'
import SearchInput from '@/components/inputs/Search.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { ImplementationsService } from '@/services/implementations'
import { ProjectCollectionResource } from '@/models/projects/ProjectCollectionResource'
import { ImplementationCollectionResource } from '@/models/implementations/ImplementationCollectionResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { RawLocation } from 'vue-router'

@Component({
  components: {
    TableRow,
    Pagination,
    TableHeading,
    TableWrapper,
    SearchInput,
    SmallLoader,
  },
})
export default class Overview extends Vue {
  // Data
  private loading: boolean = false
  private index: IndexResponse<ImplementationCollectionResource> | null = null
  private params: IndexParameters = {
    column: 'created_at',
    dir: 'desc',
    search: '',
    page: '1',
  }

  private service: ImplementationsService = new ImplementationsService()

  // Computed
  private get hasData(): boolean {
    return !!this.index && this.index.data.length > 0
  }

  private get headers(): TableElement[] {
    return this.index && this.index.elements ? this.index.elements : []
  }

  private get canCreate(): boolean {
    return userModule.canPerformAction('can_start_project')
  }

  // Search for project in table
  private search(): void {
    this.setRouteQuery()
    this.get()
  }

  private setSearch(value: string): void {
    this.params.search = value
  }

  // Lifecycle hooks
  private async created(): Promise<void> {
    this.setParamsWithQuery()
    this.get()
  }

  private async get(): Promise<void> {
    this.loading = true
    try {
      const data = await this.service.get(this.params)
      this.index = data
    } finally {
      this.loading = false
    }
  }

  // Methods
  private implementationRoute(implementation: ImplementationResource): RawLocation | void {
    // If user can see all entities hes creator and we will link the user to the dashboard
    if (implementation.canPerformAction('can_see_all_entities')) {
      return { name: 'implementation-dashboard', params: { implementation_id: `${implementation.id}` } }
    }
    // If not the user probably can only see entities and we will link the user to the first entity the user belongs
    const firstEntityUserBelongs = implementation.entities.find((entity) => entity.belongs_to)

    if (firstEntityUserBelongs) {
      return { name: 'implementation-entity-implementations', params: { implementation_id: `${implementation.id}`, entity_id: `${firstEntityUserBelongs.id}` } }
    }

    return
  }

  private setParamsWithQuery(): void {
    const params = { ...this.params, ...this.$route.query } as IndexParameters
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        if (params[param]) {
          this.$set(this.params, param, params[param])
        }
      }
    }
  }

  private setRouteQuery(): void {
    const params = { ...this.params }
    this.$router.replace({
      name: this.$route.name || '',
      query: OBJECT_VALUES_TO_STRING(params),
    })
  }

  private getRowElements(document: ProjectCollectionResource): TableDataElement[] {
    const data: TableDataElement[] = []
    if (this.index && this.index.elements) {
      this.index.elements.forEach((element: TableElement) => {
        data.push({
          value: document[element.key as keyof ProjectCollectionResource] as string,
          type: element.type,
        })
      })
    }
    return data
  }

  private setOrder(col: string, dir: string): void {
    this.params.column = col
    this.params.dir = dir
    this.params.page = '1'
    this.setRouteQuery()
    this.get()
  }

  private goToPage(val: string): void {
    this.params.page = val
    this.$nextTick(() => {
      this.get()
    })
  }
}
